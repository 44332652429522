.container {
  min-height: 100vh;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  padding: 2rem 0;
  max-width: 1200px;
  width: 100%;
}

.presentationsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.speakerdeck-embed {
  margin-top: 1rem;
  width: 100%;
  min-height: 400px;
}

@media (max-width: 600px) {
  .presentationsGrid {
    grid-template-columns: 1fr;
  }
}
