.Header {
    position: fixed;
    top: 0; /* Stick it to the top */
    max-height: 96px;
    width: 100vw;
    height: 72px;

    display: grid;
    grid-template-areas: "logo nav nav nav nav nav nav nav contact";
    
    /* Cosmetics */
    background-color: #282c34;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    z-index: 999;
}

.Logo {
    display: grid;
    grid-area: logo;
    position: relative;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.Logo img {
    position: absolute;
    max-width: 100%;
    top: 40%;
    left: 40%;
    transform: translate(-50%, -50%);
    align-items: center;
    justify-items: center;
}

.Nav {
    display: grid;
    grid-area: nav;
    grid-template-columns: repeat(5, auto);
    align-items: center;
    justify-items: center;
}
.Nav a {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    transition: 0.5s;
    text-decoration: none;
}
.Nav a:hover {
    transform: scale(1.1);
}

.Nav button {
    padding: 10px;
    outline: none;
    border: none;
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    background-color: rgba(255, 0, 0, 0.5);
    box-shadow: 0px 5px 0px 0px rgba(255, 0, 0, 0.25);
    border-radius: 10px;
    cursor: pointer;
    transition: 70ms;
}

.Nav button:active {
    transform: translateY(3px);
    box-shadow: 0px 2px 0px 0px rgba(255, 0, 0, 0.25);
}

.Burger {
    display: none;
    grid-area: burger;
    margin: 0 20px 0 0;
    padding: 0;
    justify-self: end;
    font-size: 40px;
    border: none;
    background: none;
    outline: none;
    transition: 0.1s;
}
.Burger:active {
    transform: scale(1.2);
}

.Contact {
    display: grid;
    grid-area: contact;
    grid-template-columns: repeat(4, auto);
    align-items: center;
    justify-items: center;
}

.Contact a {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    transition: 0.5s;
}

.Contact a:hover {
    transform: scale(1.5);
}

@media (max-width: 700px) {
    .Header {
        grid-template-areas: "logo burger contact" "nav nav nav";
    }
    .Nav {
        grid-template-rows: repeat(5, auto);
        grid-template-columns: none;
        grid-row-gap: 20px;

        padding: 30px 0 30px;
        background: rgba(40, 44, 47, 0.95);
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .Burger {
        display: inline;
    }
}

.NavAnimation-enter {
    opacity: 0;
    transform: scale(0.5);
}
.NavAnimation-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 350ms, transform 350ms;
}
.NavAnimation-exit {
    opacity: 1;
}
.NavAnimation-exit-active {
    opacity: 0;
    transform: scale(0.5);
    transition: opacity 350ms, transform 350ms;
}

.Contact a[href^="mailto:"]::after {
}
